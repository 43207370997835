let data = [
    {
        images: '01',
        title: 'Anders Söderström',
        designation: 'Apputvecklare & designer',
        socialNetwork: [
        ]
    },
    {
        images: '02',
        title: 'Gosha Zacharov',
        designation: 'Fullstack-utvecklare',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Christer Persson',
        designation: 'Affärsrådgivare',
        socialNetwork: [
        ]
    }
];

export default data;
