// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// Home layout
import MainDemo from './home/MainDemo';
import Runbit from './home/Runbit';
import Pay from './home/Pay';
import Svea from './home/Svea';
import Referenser from './home/Referenser';
import Growth from './home/Growth';
import Upplysa from './home/Upplysa';
import Apps from './home/Apps';
import Web from './home/Web';

// Element Layout
import Service from './elements/Service';
import Contact from './elements/Contact';

import error404 from './elements/error404';

import { BrowserRouter, Switch, Route, StaticRouter } from 'react-router-dom';

class Root extends Component {
    render() {
        // prettier-ignore
        return (
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo}/>

                <Route exact path={`${process.env.PUBLIC_URL}/referenser`} component={Referenser}/>
                <Route exact path={`${process.env.PUBLIC_URL}/runbit`} component={Runbit}/>
                <Route exact path={`${process.env.PUBLIC_URL}/pay`} component={Pay}/>
                <Route exact path={`${process.env.PUBLIC_URL}/growth`} component={Growth}/>
                <Route exact path={`${process.env.PUBLIC_URL}/upplysa`} component={Upplysa}/>
                <Route exact path={`${process.env.PUBLIC_URL}/svea`} component={Svea}/>
                <Route exact path={`${process.env.PUBLIC_URL}/web`} component={Web}/>
                <Route exact path={`${process.env.PUBLIC_URL}/apps`} component={Apps}/>
                <Route exact path={`${process.env.PUBLIC_URL}/kontakt`} component={Contact}/>

                {/* Element Layot */}
                <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>


                <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                <Route component={error404}/>
            </Switch>
        )
    }
}

if (document.body) {
    ReactDOM.hydrate(
        <BrowserRouter>
            <Root />
        </BrowserRouter>,
        document.getElementById('root'),
    );
}

/** @param locals {{webpackStats: import("webpack").Stats}} */
export default function render(locals) {
    const assets = Object.keys(locals.webpackStats.compilation.assets);
    const css = assets.filter(value => value.match(/\.css$/));
    const js = assets.filter(value => value.match(/\.js$/));

    const content = ReactDOMServer.renderToString(
        <StaticRouter context={{}} location={locals.path}>
            <Root />
        </StaticRouter>,
    );
    return `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="utf-8" />
            <link rel="shortcut icon" href="${locals.PUBLIC_URL}/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <link rel="manifest" href="${locals.PUBLIC_URL}/manifest.json" />
            <title>Quidbit</title>
            ${css.map(
                file =>
                    `<link href="${locals.PUBLIC_URL}/${file}" rel="stylesheet">`,
            )}
        </head>

        <body>
            <div id="root">
            ${content}
            </div>

            <!-- Load Facebook SDK for JavaScript -->
            <div id="fb-root"></div>

            <script>
            window.fbAsyncInit = function() {
                FB.init({
                    xfbml            : true,
                    version          : 'v6.0'
                });
            };

            (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/sv_SE/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));</script>

            <!-- Your customer chat code -->
            <div
                class="fb-customerchat"
                attribution=install_email
                page_id="1782981705259776"
                theme_color="#003F74"
                logged_in_greeting="Hejsan, Vi finns h&#xe4;r i chatten om du har n&#xe5;gra fr&#xe5;gor!"
                logged_out_greeting="Hejsan, Vi finns h&#xe4;r i chatten om du har n&#xe5;gra fr&#xe5;gor!">
            </div>

            ${js.map(
                file =>
                    `<script src="${locals.PUBLIC_URL}/${file}" async></script>`,
            )}
        </body>
        </html>
    `;
}
