import React, { Component , Fragment } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import TeamTwo from "../blocks/team/TeamTwo";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Helmet from "../component/common/Helmet";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import ContactBlob from "../elements/common/ContactBlob";

class MainDemo extends Component{
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Översikt" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area */}



                <div className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Tidigare projekt</h2>
                                    <p>Här är några projekt som QuidBit har utvecklat</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioMasonry item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="/referenser"><span>Visa fler referenser</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End portfolio Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                <div className="rn-team-wrapper ptb--120">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Team QuidBit</h2>
                                        <p>Vi som sitter i styrelsen</p>
                                    </div>
                                </div>
                            </div>
                            <TeamTwo column="col-lg-4" item="3" />
                        </div>
                    </div>
                </div>

                <div className="portfolio-related-work pt--120 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Redo att starta ett nytt projekt</span>
                                    <h2>Kontakta Anders</h2>
                                </div>
                            </div>
                        </div>
                        <ContactBlob/>
                    </div>
                </div>

                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;
