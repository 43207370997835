import React from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';

const Header = props => {
    const { color = 'default-color' } = props;

    function menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }

    function closeMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    React.useLayoutEffect(() => {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement
                        .querySelector('.submenu')
                        .classList.toggle('active');
                    this.classList.toggle('open');
                };
            }
        }
    }, []);
    return (
        // prettier-ignore
        <header className={`header-area formobile-menu header--transparent ${color}`}>
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">

                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <ul className="mainmenu">
                            <li><Link to="/">Översikt</Link></li>
                            <li className="has-droupdown"><Link to="/service" >Tjänster</Link>
                                <ul className="submenu">
                                    <li><Link to="/upplysa">Kundkommunikation</Link></li>
                                    <li><Link to="/apps">Apputveckling</Link></li>
                                    <li><Link to="/web">Webbutveckling</Link></li>
                                    <li><Link to="/growth">Marknadsföring</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/referenser">Referenser</Link></li>
                            <li><Link to="/kontakt" >Kontakt</Link></li>
                        </ul>
                    </nav>
                    {/* Start Humberger Menu  */}
                    <div className="humberger-menu d-block d-lg-none pl--20">
                        <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                    </div>
                    {/* End Humberger Menu  */}
                    <div className="close-menu d-block d-lg-none">
                        <span onClick={closeMenuTrigger} className="closeTrigger"><FiX /></span>
                    </div>
                </div>
            </div>
        </header>
    );
};
export default Header;
