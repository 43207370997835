import React, { Component , Fragment } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import ContactBlob from "../elements/common/ContactBlob";

import { Parallax } from "react-parallax";

const image1 =
  "/assets/images/upplysa/hero.png";

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Förbättra din kundkommunikation',
        description: ''
    }
]

class Upplysa extends Component{
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Upplysa" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency">
                    <Parallax bgImage={image1} strength={100}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details pt--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Upplysa</h2>
                                        <p className="subtitle">Vi skapar interaktiva meddelanden åt företag.</p>
                                        <p>Upplysa hjälper dig att automatisera kundkommunikationen genom att skapa unika och personliga webbsidor för alla kunders behov. Allt ni behöver göra är att skicka in tillräckligt med information via e-post, API-anrop, FTP eller annat sätt. Skapandet av webbsidorna, logiken bakom och hanteringen av ärendet löser QuidBit åt er!</p>
                                        <p>Vi skapar personliga och unika sidor för varje kund med hjälp av ett eget verktyg. Sidorna som genereras distribueras till erat nätverk eller ett av QuidBit CDN. När sidan är klar kontaktas kunden via en länk till sin personliga webbsida. Webbsidan rapportera tillbaka till vårat API och vår backend kan rapportera tillbaka till dig vid behov.</p>
                                    </div>
                                    <div className="portfolio-thumb-inner pt--120">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/upplysa/Main001.png" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/upplysa/upplysa_demo.png" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/upplysa/mash.png" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/upplysa/offer.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                <div className="portfolio-related-work pt--120 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Redo att starta ett nytt projekt?</span>
                                    <h2>Kontakta Anders</h2>
                                </div>
                            </div>
                        </div>
                        <ContactBlob/>
                    </div>
                </div>

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </Fragment>
        )
    }
}
export default Upplysa;
