import React from 'react';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import TabThree from '../elements/tab/TabThree';
import Helmet from '../component/common/Helmet';
import ContactBlob from '../elements/common/ContactBlob';
//import TextLoop from 'react-text-loop';

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: '',
    },
];

const Referenser = () => {
    return (
        // prettier-ignore
        <div>
            <Helmet pageTitle="Referenser" />
            {/* Start Header Area  */}
            <Header headerPosition="header--static" logo="symbol-dark" color="color-black"/>
            {/* End Header Area  */}


            <div className="slider-wrapper">
                {/* Start Single Slide */}
                {SlideList.map((value , index) => (
                    <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height" key={index}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="designer-thumbnail">
                                        <img src="/assets/images/about/designer-avatar.png" alt="Slider Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        <h1 className="title">Tidigare projekt <br/>
                                        <span> Native-appar</span>
                                        {/*<TextLoop> Doesn't work right now
                                            <span> React</span>
                                            <span> Vue.js</span>
                                            <span> Affiliate</span>
                                            <span> Apple Watch</span>
                                            <span> Prototyper</span>
                                            <span> Android-appar</span>
                                            <span> Wordpress</span>
                                        </TextLoop>*/}{" "}
                                        </h1>
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* End Single Slide */}
            </div>

            {/* Start Designer Portfolio  */}
            <div className="designer-portfolio-area ptb--120 bg_color--1">
                <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                    <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
                </div>
            </div>
            {/* End Designer Portfolio  */}

            <div className="portfolio-related-work pt--120 pb--60 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="theme-color font--18 fontWeight600">Redo att starta ett nytt projekt</span>
                                <h2>Kontakta Anders</h2>
                            </div>
                        </div>
                    </div>
                    <ContactBlob/>
                </div>
            </div>

            {/* Start Footer Style  */}
            <Footer />
            {/* End Footer Style  */}
        </div>
    );
};

export default Referenser;
