import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
  {
      icon: <FiCast />,
      title: 'Kundkommunikation',
      description: 'Vi skapar interaktiva meddelanden åt företag med hjälp av ett egenutvecklat verktyg.',
      link: '/upplysa'
  },
  {
      icon: <FiLayers />,
      title: 'Webbutveckling',
      description: 'Vi utvecklar allt från hemsidor till backend-lösningar. Många års erfarenhet inom molntjänster och webbutveckling.',
      link: '/web'
  },
  {
      icon: <FiUsers />,
      title: 'Marknadsföring',
      description: 'Vi uppfinner och utvecklar verktyg för att marknadsföra dig smartare, och framförallt mer prisvärt.',
      link: '/growth'
  },
  {
      icon: <FiMonitor />,
      title: 'Utveckling av appar',
      description: 'Har du en appidé? Varför inte göra den till verklighet! QuidBit utvecklar alla sorters appar.',
      link: '/apps'
  }
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Tjänster' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="6">
                   <div className="container">
                       <div className="row">
                           <div className="col-lg-12">
                               <div className="rn-page-title text-center pt--100">
                                   <h2 className="title theme-gradient">Tjänster</h2>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>


                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;
