import React, { Component } from "react";
import {FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

class ContactBlob extends Component{
    render(){
        return(
            <React.Fragment>
              <div className="rn-contact-top-area ptb--120">
                  <div className="container">

                      <div className="row">
                          {/* Start Single Address  */}
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                              <div className="rn-address">
                                  <div className="icon">
                                      <FiHeadphones />
                                  </div>
                                  <div className="inner">
                                      <h4 className="title">Telefonsamtal</h4>
                                      <p><a href="tel:+46702405100">070 - 240 51 00</a></p>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Address  */}

                          {/* Start Single Address  */}
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                              <div className="rn-address">
                                  <div className="icon">
                                      <FiMail />
                                  </div>
                                  <div className="inner">
                                      <h4 className="title">E-postadress</h4>
                                      <p><a href="mailto:admin@gmail.com">anders@quidbit.se</a></p>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Address  */}

                          {/* Start Single Address  */}
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                              <div className="rn-address">
                                  <div className="icon">
                                      <FiMapPin />
                                  </div>
                                  <div className="inner">
                                      <h4 className="title">Plats</h4>
                                      <p>Linköping</p>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Address  */}

                      </div>
                  </div>
              </div>
            </React.Fragment>
        )
    }
}

export default ContactBlob;
