import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class Growth extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Marknadsföring' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Vi kan marknadsföring</h2>
                                    <p>Vi älskar Growth hacking, vilket vi har många års erfarenhet av.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Fejka en viral succé</h2>
                                        <p className="subtitle">Vi har verktygen som hjälper dig oavsett dina avsikter. Med hjälp av våra verktyg når Du garanterat den billigaste målgruppen</p>
                                    </div>
                                    <div className="portfolio-thumb-inner pt--60">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/growth/viral.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/growth/facebook.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-portfolio-details ptb--120 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Automatiserad twitterbot</h2>
                                        <p className="subtitle">"Så illa kan det gå om du låter en robot ragga kunder via Twitter"</p>
                                        <p>Ett system som automatiskt letar upp och kontaktar personer på Twitter som uppfyller alla krav för att gilla Runbit.</p>
                                        <div className="service-btn mt--30">
                                            <a className="btn-transparent rn-btn-dark" href="https://www.breakit.se/artikel/992/sa-illa-kan-det-ga-om-du-later-en-robot-ragga-kunder-via-twitter"><span className="text">Läs artikel på Breakit</span></a>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner pt--60">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/growth/twitter.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>SMS-flirta med hela Sverige</h2>
                                        <p className="subtitle">"Hörde en fågel viska att du är rätt söt, NAMN!"</p>
                                        <p>När vi marknadsförde Snackis ville vi komma undan billigt med reklam. Vi skapade då en automatisk SMS-BOMB som flirtade med hela Sverige!</p>
                                        <p>Med ett abonnemang från Telia med obegränsade SMS, en Androidtelefon och 2st program:</p>
                                        <p>Första programmets uppgift var att skicka ut SMS på löpande band. Med hjälp av listor från Statistiska centralbyrån över de vanligaste namnen i Sverige utförde programmet slumpmässiga sökningar i 118100:s API för att hitta mobilnummer. Alla SMS började med “Hörde en fågel viska…“ och deras namn.</p>
                                        <p>Detta program vaknade till liv för varje nytt SMS. Genom att analysera vilka ord som kom tillbaka kunde programmet på egen hand räkna ut bra svar tillbaka eller i värsta fall “Oj, måste ha tagit fel nummer”.</p>
                                        <p className="subtitle">Resultat</p>
                                        <p>Efter bla många samtal från arga 16åringar som påstod att vi flirtat med deras flickvänner, och av moraliska skäl valde vi att stänga av boten.</p>
                                    </div>
                                    <div className="portfolio-thumb-inner pt--60">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/growth/smsbomb.jpg" alt="Portfolio Images"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Fuska på Google Play</h2>
                                        <p className="subtitle">QuidBit sitter idag på unik data bestånde av 11.6 miljoner avläsningar på Google Play. Med hjälp av maskininlärning kan QuidBit använda datan till att ge direkta fördelar för att öka synligheten av appar i Google Play.</p>
                                        <div className="service-btn mt--30">
                                            <a className="btn-transparent rn-btn-dark" href="https://www.breakit.se/how-to/6937/5-knep-for-att-fa-din-app-att-klattra-hogst-upp-pa-google-play"><span className="text">Läs artikel på Breakit</span></a>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner pt--60">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/growth/gp.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </React.Fragment>
        )
    }
}
export default Growth;
