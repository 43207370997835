import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const List = [
    {
        image: '/assets/images/ref/kartjakt_small.jpg',
        bigImage: '/assets/images/ref/kartjakt.jpg',
        category: 'Egna projekt',
        title: 'Förvandla din stad till ett spel'
    },
    {
        image: '/assets/images/ref/loan_small.jpg',
        bigImage: '/assets/images/ref/loan_big.jpg',
        category: 'Egna projekt',
        title: 'Samla alla lån på ett ställe'
    },
    {
        image: '/assets/images/ref/watch.jpg',
        bigImage: '/assets/images/ref/watch_big.jpg',
        category: 'Appar',
        title: 'Träna med smarta klockor'
    },
    {
        image: '/assets/images/ref/frakta_small.png',
        bigImage: '/assets/images/ref/frakta.jpg',
        category: 'Appar',
        title: 'Annonsera ut fraktmöjligheter'
    },
    {
        image: '/assets/images/ref/snackis_logo.png',
        bigImage: '/assets/images/ref/snackis.jpg',
        category: 'Appar',
        title: 'Telefondejtning som matchar'
    },
    {
        image: '/assets/images/ref/esbe_small.jpg',
        bigImage: '/assets/images/ref/esbe.jpg',
        category: 'Appar',
        title: 'Beräkna fram rätt ventil'
    },
    {
      image: '/assets/images/ref/selfiejobs_small.jpg',
      bigImage: '/assets/images/ref/selfiejobs.jpg',
      category: 'Appar',
      title: 'Sök jobb med en "LIKE"'
    },
    {
      image: '/assets/images/ref/bird.png',
      bigImage: '/assets/images/ref/bird.png',
      category: 'Appar',
      title: 'Akta dig från att bli skjuten'
    },
    {
      image: '/assets/images/ref/trady3.jpg',
      bigImage: '/assets/images/ref/trady.jpg',
      category: 'Appar',
      title: 'Byt saker med varandra'
    },
]

class TabApps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const {column } = this.props;
        const { tab1, isOpen } = this.state;
        return (
            <div>
                <div className="row row--35">
                    {List.map((value , index) => (
                        <div className={`${column}`} key={index}>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={List[tab1].bigImage}
                                    nextSrc={List[(tab1 + 1) % List.length]}
                                    prevSrc={List[(tab1 + List.length - 1) % List.length]}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                    onMovePrevRequest={() =>
                                    this.setState({
                                        tab1: (tab1 + List.length - 1) % List.length,
                                    })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + 1) % List.length,
                                        })
                                    }
                                    imageLoadErrorMessage = 'Image Loading ...'
                                    enableZoom={false}
                                />
                            )}
                            <div className="item-portfolio-static">
                                <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                    <div className="portfolio-static">
                                        <div className="thumbnail-inner">
                                            <div className="thumbnail">
                                                <a href="#portfolio-details">
                                                    <img src={`${value.image}`} alt="Portfolio Images"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                <h4><a href="#portfolio-details">{value.title}</a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}


export default TabApps
