import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ContactBlob from "../elements/common/ContactBlob";
import TeamTwo from "../blocks/team/TeamTwo";

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Kontakt' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Kontakta oss</h2>
                                    <p>Nedan hittar du kontaktuppgifter </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                <div className="rn-team-wrapper ptb--120">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Team QuidBit</h2>
                                        <p>Vi som sitter i styrelsen</p>
                                    </div>
                                </div>
                            </div>
                            <TeamTwo column="col-lg-4" item="3" />
                        </div>
                    </div>
                </div>

                <div className="portfolio-related-work pt--120 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Redo att starta ett nytt projekt</span>
                                    <h2>Kontakta Anders</h2>
                                </div>
                            </div>
                        </div>
                        <ContactBlob/>
                    </div>
                </div>

                <Footer />

            </React.Fragment>
        )
    }
}
export default Contact
