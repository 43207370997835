import React, { Component , Fragment } from "react";
import Testimonial from "../elements/Testimonial";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { videoTagString, VideoTag } from 'react-video-tag';
import Helmet from "../component/common/Helmet";
videoTagString({ src: '/assets/images/service/video.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Förvandla din stad till ett spel',
        description: 'Gå, jogga, spring och upptäck mer, din stad förvandlas till ett spel!'
    }
]

class Runbit extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Runbit" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <style>
                              {`
                                .video-background video {
                                  width: 100%;
                                  height: 100%;
                                  object-fit: cover;
                                }`}
                            </style>
                            <div className="video-background">
                                <VideoTag style={{width: "100%"}} autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`}  src={`${"/assets/images/service/runbit.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}


                </div>
                {/* End Slider Area   */}





                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Runbit</h2>
                                        <p className="subtitle">Gå, jogga, spring och upptäck mer, din stad förvandlas till ett spel! Plocka stjärnor, fly från monster, fånga djur och utmana dina vänner!</p>
                                        <p>Appen är skriven native i Obj-C/Swift för iOS. Med stöd för iPhone, iPad och Apple Watch. Android-appen är skriven native i Java och fungerar på tablets, telefoner och Android Wear. Windows-phone appen är skriven i C# (native) och har stöd för alla Windows telefoner.</p>
                                        <p>Totalt 130.000+ användare. Nominerad av Apple till första sidan på App Store i 23 länder.</p>
                                        <p>Publicerad i tidningar som Aftonbladet, Runner’s World, Tom’s Hardware, Feber, Corren, Breakit, PC För Alla, Mobil, Metro, Hälsa & Fitness, Women’s Health, m.fl. </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Kategori</span>
                                                <h4>Appar</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Typ</span>
                                                <h4>Eget projekt</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Mer info</span>
                                                <a href="https://runbit.se"><h4>runbit.se</h4></a>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="portfolio-thumb-inner pt--120">

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-04.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </Fragment>
        )
    }
}
export default Runbit;
