import React, { Component , Fragment } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { Parallax } from "react-parallax";

const image1 =
  "/assets/images/svea/hero.jpg";

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Utveckling av en ny bank-app',
        description: ''
    }
]

class Svea extends Component{
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Svea" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency">
                    <Parallax bgImage={image1} strength={100}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index} data-black-overlay="8">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Svea Ekonomi AB</h2>
                                        <p className="subtitle"> Som konsult via Nicknamed AB utvecklades en bank riktad för ungdomar åt Svea Ekonomi. </p>
                                        <p>Anders har tagit fram och skapat prototyper över hur framtidens bankappar skulle kunna fungera. Nya betalflöden togs fram för att förenkla fakturabetalning, nya sätt att skanna fakturor och ett gränssnitt för att överblicka din ekonomi på ett personligare sätt.</p>
                                        <p>Gosha utvecklade ett webb-baserat back office-system i React. Var under en period team lead för frontend-teamet.</p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Kategori</span>
                                                <h4>Web & Appar</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Typ</span>
                                                <h4>Konsult</h4>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="portfolio-thumb-inner pt--120">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/svea/svea.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/svea/preview.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/svea/svea_credit_info.png" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </Fragment>
        )
    }
}
export default Svea;
