import React, { Component , Fragment } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { Parallax } from "react-parallax";

const image1 =
  "/assets/images/pay/pay-hero.jpg";

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Kort- & Betalterminal på nätet',
        description: ''
    }
]

class Pay extends Component{
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Pay" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency">
                    <Parallax bgImage={image1} strength={500}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>QuidBit Pay</h2>
                                        <p className="subtitle">Med QuidBit Pay kan Du ta betalt av alla kunder i hela världen på det sätt och med den valuta de vill betala.</p>
                                        <p>Appen är skriven native i Obj-C/Swift för iOS. Med stöd för iPhone, iPad och Apple Watch. Android-appen är skriven native i Java och fungerar på tablets, telefoner och Android Wear. Windows-phone appen är skriven i C# (native) och har stöd för alla Windows telefoner.</p>
                                        <p>Totalt 130.000+ användare. Nominerad av Apple till första sidan på App Store i 23 länder.</p>
                                        <p>Publicerad i tidningar som Aftonbladet, Runner’s World, Tom’s Hardware, Feber, Corren, Breakit, PC För Alla, Mobil, Metro, Hälsa & Fitness, Women’s Health, m.fl. </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Kategori</span>
                                                <h4>Web</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Typ</span>
                                                <h4>Eget projekt</h4>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="portfolio-thumb-inner pt--120">
                                        <div className="thumb mb--60">
                                            <img src="/assets/images/pay/pay-hero2.jpg" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/pay/pay-explain.png" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/pay/pay-explain.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </Fragment>
        )
    }
}
export default Pay;
