import React, { Component } from "react";

const PortfolioList = [
    {
        image: 'image-1',
        category: 'Runbit',
        title: 'Förvandla din stad till ett spel',
        link: '/runbit'
    },
    {
        image: 'image-2',
        category: 'Svea Ekonomi AB',
        title: 'Utveckling av en ny Bank-app',
        link: '/svea'
    },
    {
        image: 'image-3',
        category: 'Upplysa',
        title: 'Förbättra din kundkommunikation',
        link: '/upplysa'
    },
    {
        image: 'image-4',
        category: 'QuidBit Pay',
        title: 'Ta betalt smidigare',
        link: '/pay'
    },
    {
        image: 'image-5',
        category: 'Growth Hacking',
        title: 'Smartare marknadsföring',
        link: '/growth'
    },
    {
        image: 'image-6',
        category: 'Konsultjobb',
        title: 'Tidigare appar vi byggt',
        link: '/referenser'
    }
]

class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="Tilt-inner">
                            <a href={value.link}>
                              <div className="portfolio">
                                  <div className="thumbnail-inner">
                                      <div className={`thumbnail ${value.image}`}></div>
                                      <div className={`bg-blr-image ${value.image}`}></div>
                                  </div>
                                  <div className="content">
                                      <div className="inner">
                                          <p>{value.category}</p>
                                          <h4><a href={value.link}>{value.title}</a></h4>
                                          <div className="portfolio-button">
                                              <a className="rn-btn" href={value.link}>Läs mer</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </a>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;
