import React, { Component , Fragment } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import ContactBlob from "../elements/common/ContactBlob";
import TabWeb from "../elements/tab/TabWeb";

class Web extends Component{
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Webbutveckling" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="6">
                   <div className="container">
                       <div className="row">
                           <div className="col-lg-12">
                               <div className="rn-page-title text-center pt--100">
                                   <h2 className="title theme-gradient">Söker ni en fullstack-utvecklare?</h2>
                                   <p>Kontakta oss för mer detaljer</p>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>React & Vue.js</h2>
                                        <p className="subtitle">Gosha utvecklar allt från hemsidor till backend-lösningar. Många års erfarenhet inom molntjänster och webbutveckling.</p>
                                        <p>Utförda uppdrag har blivit byggda med molntjänster för skalbarhet och flexibilitet med Google Cloud (App Engine, DataStore, Firebase, Storage), Amazon Web Services (S3, EC2, ECS, Lambda, DynamoDB, API Gateway) och Microsoft Azure (Compute, Blob/Queue Storage, SQL Database).</p>
                                    </div>
                                    <div className="portfolio-thumb-inner pt--60">

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/ref/web_preview.png" alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--60">
                                            <img src="/assets/images/svea/preview.jpg" alt="Portfolio Images"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}


                <div className="portfolio-related-work pt--120 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2>Tidigare projekt</h2>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                            <TabWeb column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>

                <div className="portfolio-related-work pt--120 pb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Behöver ni en fullstack-utvecklare?</span>
                                    <h2>Kontakta Anders</h2>
                                </div>
                            </div>
                        </div>
                        <ContactBlob/>
                    </div>
                </div>

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </Fragment>
        )
    }
}
export default Web;
