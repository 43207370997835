import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Att springa en runda i solen på lunchen har blivit lite av mitt nya bästa. Och nu har jag hittat appen Runbit, som gör det hela ännu lite härligare.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span><a href="https://web.archive.org/web/20160314041323/http://www.halsaochfitness.se/en-chefredaktors-vardag/run-for-the-stars/">Hälsa & Fitness</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Runbit är en annorlunda löparapp. Här blir träningen en lek. Den sprider ut virtuella stjärnor på en karta som du sedan ska springa och samla upp.</p>
                                    </div>
                                    <div className="author-info">
                                      <h6><span><a href="https://web.archive.org/web/20160402124504/http://pcforalla.idg.se/2.1054/1.604041/dagens-app--lekande-loptraning">Pc för alla</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Runbit är en app för den som vill ut och springa, cykla, rida, eller gå med vagnen och som vill hitta lite nya vägar.</p>
                                    </div>
                                    <div className="author-info">
                                      <h6><span><a href="https://web.archive.org/web/20160402124504/http://feber.se/mobil/art/315752/ny_app_ska_f_dig_att_springa_n/">Feber</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Samla stjärnor, fånga djur eller bli jagad av monster. LiU studenten Anders Söderström har utvecklat en app som ska göra löprundan roligare.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span><a href="https://web.archive.org/web/20160402124504/http://www.corren.se/nyheter/linkoping/appen-som-ska-fa-dig-att-rora-mer-pa-dig-8176078.aspx">Corren</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Har du svårt att hitta nya löprundor? Testa app­en "Runbit". Den hjälper dig att upptäcka nya områden och förvandlar din träning till ett roligt äventyr.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span><a href="https://web.archive.org/web/20160402124504/http://www.aftonbladet.se/wellness/article21079463.ab">Wellness</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Svenska Runbit innehåller bara grundfunktioner som att mäta tid, distans och tempo. Den tillför dock en extra dimension - ett antal stjärnor som finns utspridda på kartan.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span><a href="https://web.archive.org/web/20160402124504/http://www.aftonbladet.se/tester/article21211412.ab">Aftonbladet</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Runbit är namnet på en app som ska motivera användare till att ha kul när de tränar. Genom att placera ut olika objekt på appens karta</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span><a href="https://web.archive.org/web/20160402124504/http://www.mobil.se/nyheter/jogga-efter-stj-rnor#.VcrelqODi5I">Mobil</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Anders Söderström vill att människor ska leta efter stjärnor i stället för att räkna kilometer. Med hans nyutvecklade app ska träningen bli roligare.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span><a href="https://web.archive.org/web/20160402124504/http://www.ltz.se/jamtland/ostersund/anders-vill-fa-dig-att-lopa-mellan-stjarnorna">LT Östersund</a></span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
